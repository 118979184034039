export const Urls = {
    BaseURL: 'cdn-base-url',
    Domain: 'https://account.pplfirst.com',
    FmsURL: 'https://fms.pplfirst.com',

    // Client apps
    ppl_participant_connect_url: '/s4s/CustomPage/Index/35',
    ppl_provider_connect_url: '/s4s/CustomPage/Index/34',

   // Organizations
   org_TennCare_id: 'ce287513-4667-463c-89ec-ac17002bfac5',
   org_pennsylvania_odp_id: '37b8f2ff-3c33-4556-8221-ab9d00a4d3a4',
   org_ohio_homecare_id : 'd9dbafe3-8e81-4fb2-9976-aada01669b63',

    // Programs
    program_virginia: 'Virginia Cardinal Care',
    program_va_ccc_id: 'a9e38c1b-734a-47ae-8341-ac0700b03759',
    program_tenncare_id: 'd16e5fa2-a316-47f7-89bb-aeb5007dc460',
    program_tenncare_ac_id: 'b4c04c92-458a-4d7d-ad56-aeb5007c6394',
    program_tenncare_ag_id: 'b8914801-503f-4cd1-b155-aeb5007d5459',
    program_tenncare_ecf_id: '3a956c36-9d19-4161-8e48-ae98010c86e3',
    program_tenncare_kb_id: '8d5db84e-a9b9-481b-afd7-ac0c00786eae',
    program_tenncare_vshp_id: 'd16e5fa2-a316-47f7-89bb-aeb5007dc460',
    program_tenncare_sdwp_id: '1ec7583d-f3d2-4a41-8e99-aecc009cf373',
    program_tenncare_tcad_id: 'a90f0c39-cea2-4879-a792-afa100ab78bf',
    program_md_dda_id: '01c5ef03-01cf-4c50-aaa5-aeed00e31980',
    program_ar_ic_id: '75f484fa-f1bd-4dcc-92cd-4140efdfaa23',
    program_al_dmh_id: 'fc05b030-ff24-4072-8f6c-ac60007a1a2e',
    program_ks_steps_id: '93cefe92-5a46-469b-b29c-acfc003bc2d4',
    program_oh_rise_id: '1d47918d-9787-428b-9d80-ae9200bf0dd9',
    program_florida_PDO_id: 'd5b58299-b6b5-413b-8997-af8901091e14',
    program_pennsylvania_odp_id: '506499d1-23d2-4b03-981f-33272205a48b',
    program_ca_sdp_id: '8c965883-6485-42a3-b3c7-df7d20f35eb3',
    program_oh_homecare_id: '4a696efa-3df2-4de3-9a95-08dee0b0ace2',
    program_oh_mycare_id: '2d87d070-3994-442c-a3fa-abb400f3922e',

    // Roles
    role_stateAdmin_id: '4fa0aee3-ff4b-4940-b914-3c0bb36380e2',
    role_service_facilitator_id: '3915a945-7676-4d43-bf72-41769ed07196',

    // Page ids
    page_myProviders: '2',
    page_myPrograms: '3',
    page_myDesignatedRep: '4',
    page_mySignatures: '5',
    page_addDesignatedRep: '6',
    page_addProvider: '7',
    page_sagDashboard: '8',
    page_myDetails: '9',
    page_myParticipants: '15',
    page_caseManagerDashboard: '16',
    page_servicesPayrates: '33',
    page_contactUs: '22',
    page_pplConnect: '23',
    page_budgetManagement: '26',
    page_myCourses: '27',
    page_inviteToEnroll: '28',
    page_directProviderReg: '29',
    page_agencyDashboard: '31',
    page_agencyEnrollment: '32',
    page_myRepAgreement: '34',
    page_barCodePage: '38',
    page_pplConnectCreate: '40',
    page_pplConnectMessages: '41',
    page_sagCreateEnrollment: '42',
    page_agencyPaymentDetails: '43',
    page_purchaseRequest: '44',
    page_hireProviderAdmin: '45',
    page_reports: '47',
    page_crmRedirect: '48',

    waivertype_id: '2d666d26-8ade-4d3a-bb82-c1dd3cc59abd',
    waivertype_lah: '7e5bd59d-7f37-4e69-81f5-e2a08c7eb843',
    waivertype_cwp: '10d3df36-36c6-4823-b309-22f5579fb30e',

    perchaseRequestFormId_OhioRise: '6298f6df5a5c0e000188a5c3',
    perchaseRequestFormId_md_dda: '63bd92fd4e59983ae6a55047',
    perchaseRequestFormId_ca_sdp: '663bee79df3d03a175225aee',

    // Tenncare VSHP referral form
    referral_ten_confirm: 'bf544039-05e1-45bf-a913-e7cbc55f6024',
    referral_ten_vshp_value: '5e4e9176-b560-4593-b532-0ac2582038d6',

    // Tenncare AC referral form
    referral_confirmPgm_ten_ac_quesId: 'bf544039-05e1-45bf-a913-e7cbc55f6024',
    referral_confimPgm_ten_ac_dropDownId: '5e4e9176-b560-4593-b532-0ac2582038d6',
    referral_mCO_ten_ac_quesId: '2e41efda-3a50-43e3-bcc2-5ee57aec5bc4',

    // Tenncare ECF referral form
    referral_confirmPgm_ten_ecf_quesId: 'bf544039-05e1-45bf-a913-e7cbc55f6024',
    referral_confirmPgm_ten_ecf_dropDownId: '5e4e9176-b560-4593-b532-0ac2582038d6',

    // Tenncare KB referral form
    referral_confirmPgm_ten_kb_quesId: '3b4b509a-74a9-4d93-9d5d-b76256f56989',
    referral_confirmPgm_ten_kb_dropDownId: '54cec912-1057-4d7c-bcd4-ecc80c47a47e',

    // Tenncare SDWP referral form
    referral_confirmPgm_ten_sdwp_quesId: 'bf544039-05e1-45bf-a913-e7cbc55f6024',
    referral_confirmPgm_ten_sdwp_dropDownId: '5e4e9176-b560-4593-b532-0ac2582038d6',
    referral_mCO_ten_sdwp_quesId: '2e41efda-3a50-43e3-bcc2-5ee57aec5bc4',

    // Oh Mycare and Home Care 
    referral_confirmPgm_oh_mycare_quesId: '006a346d-2c95-4605-a6ca-69bde0488c65',
    referral_confirmPgm_oh_mycare_dropDownId: 'd4c6010e-b50f-4ab5-8764-b9fc5305f61e',
    referral_confirmPgm_oh_homecare_quesId: '006a346d-2c95-4605-a6ca-69bde0488c65',
    referral_confirmPgm_oh_homecare_dropDownId: 'ad40bbc9-9a42-4d80-ba5c-9029c9f5074e',

    // Arkansas IC referral form
    referral_mCO_ar_ic_quesId: '2e41efda-3a50-43e3-bcc2-5ee57aec5bc4',

    // Tenncare VSHP provider referral form
    prReferral_pgmSelection_ten_vshp_quesId: '8fd2948d-387f-4b80-b042-5d3bb51afe86',
    prReferral_pgmSelection_ten_vshp_dropDown: 'a89e54f6-70f5-4807-8636-58ebd4d64fdc',

    // Tenncare AC provider referral form
    prReferral_pgmSelection_ten_ac_quesId: '8fd2948d-387f-4b80-b042-5d3bb51afe86',
    prReferral_pgmSelection_ten_ac_dropDown: '455b66f0-0674-4e6e-ac35-b1282a924c42',

    // Tenncare AG provider referral form
    prReferral_pgmSelection_ten_ag_quesId: '8fd2948d-387f-4b80-b042-5d3bb51afe86',
    prReferral_pgmSelection_ten_ag_dropDown: '10200b52-8d89-4321-b4b3-844d8ba00c35',

    // Tenncare ECF provider referral form
    prReferral_pgmSelection_ten_ecf_quesId: '8fd2948d-387f-4b80-b042-5d3bb51afe86',
    prReferral_pgmSelection_ten_ecf_dropDown: '1a02b7a0-5c3e-44bc-8585-f38e63f31a1c',

    // Tenncare KB provider referral form
    prReferral_pgmSelection_ten_kb_quesId: '8fd2948d-387f-4b80-b042-5d3bb51afe86',
    prReferral_pgmSelection_ten_kb_dropDown: 'cf970b9c-5f66-437d-9ebe-b481307b85f7',

    // Tenncare SDWP provider referral form
    prReferral_pgmSelection_ten_sdwp_quesId: '8fd2948d-387f-4b80-b042-5d3bb51afe86',
    prReferral_pgmSelection_ten_sdwp_dropDown: 'e3489221-6fcd-4162-8df6-bf579345ffde',

    // OH HOmecare provider referral
    prReferral_pgmSelection_oh_homecare_quesId: '608de709-09d2-49a0-af83-be539d9bc800',
    prReferral_pgmSelection_oh_homecare_dropDown: '3c2c4216-6fe3-499e-96f6-13c536b934c0',

    // OH Mycare provider referral
    prReferral_pgmSelection_oh_mycare_quesId: '608de709-09d2-49a0-af83-be539d9bc800',
    prReferral_pgmSelection_oh_mycare_dropDown: '96aebcdb-6803-430e-8890-c2f2aa8b5005',

    // MD DDA provider referral form
    prReferral_waiverSelection_md_dda_quesId: 'aa44842d-ddac-4d9a-8505-946f2a43523a',
    prReferral_waiverSelection_md_dda_dropDown_cpw: '0804d3b5-4a61-4e0a-9e4e-501fe923a7fd',
    prReferral_waiverSelection_md_dda_dropDown_csw: '0c806a30-8ed6-41de-898d-daedef874ec4',
    prReferral_waiverSelection_md_dda_dropDown_fsw: 'b6930f19-be93-45ce-a4df-a6a56852ec51',

    // AR IC provider referral form
    prReferral_waiverSelection_ar_ic_quesId: 'c17ee485-a9eb-41d0-b035-99ba9964e7de',
    prReferral_waiverSelection_ar_ic_dropDown_ICOnly: '8a861c8a-47f7-4c70-8e81-c61294fd78a4',
    prReferral_waiverSelection_ar_ic_dropDown_ARChoices: '51e67242-9eda-4d81-b417-e04638d91963',

    // AL DMH referral form
    referral_program_id: 'fc05b030-ff24-4072-8f6c-ac60007a1a2e',
    referral_question_id_sca: '42505d53-cad2-4b40-b05f-1b3b1837c471',
    referral_mco_id_sca: 'c58f7b8c-8332-49f2-bc42-acb70007e9f1',
    referral_question_id_sc: 'bfdc7696-2ec8-40b4-82ec-efcfe18a21ff',
    referral_mco_id_sc: 'c39a5098-cca1-4796-9980-acb80091b05c',
    referral_pplid: 'CM-0010255',
    referral_textValue_sca: 'Alabama Support Coordinator Agency, AL_DMH',
    referral_textValue_sc: 'User, Default, alscdefault@publicpartnerships.com',
    referral_lastName: 'User',
    referral_firstName: 'Default',
    referral_email: 'alscdefault@publicpartnerships.com',

    // Florida PDO
    prReferral_region_floridaPDO_quesId: '5f0e9a0c-be89-4b87-a04b-37858b46717a',

    // Ohio Rise MCO
    ohio_rise_mco: 'f5b426ca-fa44-452c-98c3-ae98009e8848',

    // TN AC MCO
    tn_ac_mco: 'ebedcdc6-8832-4593-98c1-aec10096636a',

    // TN SDWP MCO
    tn_sdwp_mco: '8526b9f1-e197-412e-8875-af8601603f15',

    // AR IC MCO
    ar_ic_mco: '1f3b560a-a4ca-40df-b9f2-afc700d7ab7f',

    // VA CCC Registration form
    va_ccc_registration_form_id: '5fb78e8bfe1374000197a760',

    // Pre-register a vendor form - Program selection question Id
    pre_register_vendor_program_select_question_id: '8fd2948d-387f-4b80-b042-5d3bb51afe86',

    // TN Pre-register a vendor form program Id's
    tn_ac_program_id: '455b66f0-0674-4e6e-ac35-b1282a924c42',
    tn_ag_program_id: '10200b52-8d89-4321-b4b3-844d8ba00c35',
    tn_ecf_program_id: '1a02b7a0-5c3e-44bc-8585-f38e63f31a1c',
    tn_kb_program_id: 'cf970b9c-5f66-437d-9ebe-b481307b85f7',
    tn_sdwp_program_id: 'e3489221-6fcd-4162-8df6-bf579345ffde',
    tn_vshp_program_id: 'a89e54f6-70f5-4807-8636-58ebd4d64fdc',
    tn_tcad_program_id: 'ffb84144-4325-4d21-b926-64a8001548e7',

    // PPL Connect
    pplConnect_approved: '4ce13d86-df3b-49a5-bdb0-ad040066dec9',

    // Time of Day
    connect_day: '7C24DB88-B0F3-40F7-8052-AD51008F28A7',
    connect_night: '4DDEB6F0-288E-401C-BEE3-AD51008F28A7',

    // Availability
    connect_Monday: 'AA126533-A934-4926-9ECD-AC710041366B',
    connect_Tuesday: 'C030FD89-79B3-4547-B042-AC710041366B',
    connect_Wednesday: '9CE85371-CBB7-40E5-B910-AC710041366B',
    connect_Thursday: '48617522-AC1F-442E-A383-AC710041366B',
    connect_Friday: 'D12F12A2-4D4E-42C3-BD81-AC710041366B',
    connect_Saturday: '5D020D26-AB46-4067-81AE-AC710041366B',
    connect_Sunday: '661D02CA-1340-40AB-B3F5-AC710041366B',

    // Professional Licenses
    prof_licence_CPR: 'DF063AFF-16CF-43D0-A375-AF3900FA3B66',
    prof_licence_FirstAid: '0357A504-90A3-46BE-AFD7-AF3900FA3B66',
    prof_licence_RN: '42A2F5EC-D589-4E92-907C-AC71003FDFD5',
    prof_licence_LPN: '06FFB8B3-6649-42D3-A9E8-AC71003FDFD5',
    prof_licence_SpeechTherapy: '6AAEBFCF-84B7-4E24-A796-AC71003FDFD5',
    prof_licence_PhysicalTherapy: 'A71648F3-0940-4902-A622-AC71003FDFD5',
    prof_licence_CNA: 'C074C3AA-4D51-4F93-94D1-AC71003FDFD5',
    prof_licence_Other: '98EE2719-AFE7-4F8B-A2E2-AC71003FDFD5',
    prof_licence_OccupationalTherapy: 'A9C31F6C-A5BB-4116-AACF-AC71003FDFD5',

    // Care preferences
    care_pref_NonSmoker: 'C01E4B67-A43B-49E5-8363-AC710040160E',
    care_pref_ComfortableWithChildren: 'C2D8C6C6-87D7-4C7C-ADC8-AC710040160E',
    care_pref_ComfortableWithPets: '826F6F02-C81F-47E1-B804-AC710040160E',
    care_pref_SmokeFree: 'F6142023-C781-46FE-BE57-AC710040160E',
    care_pref_Transportation: 'ECD3E4F2-026E-440C-9B94-AC710040160E',

    // Services
    services_ADL_Continence: '18DAABE3-B827-4D6B-B8DD-AC710042B0C9',
    services_IADL_ManagingMedication: '41D2C067-E135-415C-88FE-AC710042B0C9',
    services_IADL_Housework: '532D50D2-A238-4EAE-A16B-AC710042B0C9',
    services_IADL_ManagingMoney: '49CB68E0-8453-4B44-B4F4-AC710042B0C9',
    services_ADL_Ambulations: '81EA4BB0-D521-4C0A-BB1D-AC710042B0C9',
    services_ADL_PersonalHygiene: '92C67912-0DB3-4ADD-A8D2-AC710042B0C9',
    services_ADL_Toileting: '6845D99E-5872-4666-A89A-AC710042B0C9',
    services_IADL_MealPreparation: 'B53CAEEA-76AE-4416-818A-AC710042B0C9',
    services_IADL_ManagingCommunication: '46DE1F76-2F38-4E0E-91CA-AC710042B0C9',
    services_IADL_Transportation: 'E8C74D55-DF89-47A9-AE1B-AC710042B0C9',
    services_ADL_Dressing: 'AACBC3B0-0D9C-45D2-8D26-AC710042B0C9',
    services_ADL_Feeding: '7E420739-8ACF-459A-BDCF-AC710042B0C9',
    services_IADL_Shopping: '4D4860E4-493A-4470-923E-AC710042B0C9',

    // Conversational languages
    convLang_Somali: '3189515E-197E-4174-82E5-AC710040D437',
    convLang_Punjabi: '67FF0F0F-D201-45FB-B87B-AC710040D437',
    convLang_Italian: '6544AE22-67C1-4422-A864-AC710040D437',
    convLang_Vietnamese: '4DF63BE4-9208-460D-BD75-AC710040D437',
    convLang_Cambodian: '5FB240CF-D7A5-4F47-8150-AC710040D437',
    convLang_German: '0854C796-F9EB-4BB5-BFC1-AC710040D437',
    convLang_Cantonese: 'AB2BA54F-60D9-48B6-AB00-AC710040D437',
    convLang_Amhari: 'AF3E18F6-9EA0-4F2D-86E4-AC710040D437',
    convLang_Khmer: 'E14D7336-B835-456D-909F-AC710040D437',
    convLang_Creolen: '4E1971DB-DA15-44F3-9F6C-AC710040D437',
    convLang_Mandarin: '8FB0734E-62A9-487A-A9C9-AC710040D437',
    convLang_Arabic: '0507B7A0-720D-48AD-95FB-AC710040D437',
    convLang_Haitian: '7738E340-2A40-41F2-861D-AC710040D437',
    convLang_English: '9638C40B-3E0A-4749-AD0E-AC710040D437',
    convLang_Chinese: '7B3E4673-96DC-455E-AAD1-AC710040D437',
    convLang_Portuguese: '9C1A1BCE-7518-44A4-910E-AC710040D437',
    convLang_French: 'C8B2AD5A-93BA-49B4-B13A-AC710040D437',
    convLang_Tagalog: '2E74DB8B-664A-4FCB-AF96-AC710040D437',
    convLang_Russian: '5F7A666C-5C95-4077-AA46-AC710040D437',
    convLang_Farsi: '9C76821C-DC0F-4254-8D0B-AC710040D437',
    convLang_Korean: '31014831-653B-4379-9DD2-AC710040D437',
    convLang_Spanish: 'C9EE4067-2185-44A7-9568-AC710040D437',
    convLang_Japanese: '1AE1F409-BC08-42D8-AFB2-AC710040D437',

    // Allergies
    allergies_none: '84EE8E25-8BF7-4DE2-8246-AC710041A01C',
    allergies_Grass: '58AF6ADF-6B16-4668-8E97-AC710041A01C',
    allergies_Dust: 'ECC7AA64-997C-4AD1-8128-AC710041A01C',
    allergies_Other: 'D42EE526-A1AA-41C8-8487-AC710041A01C',
    allergies_Fish: '90AFA2D4-5564-4AC3-95A8-AC710041A01C',
    allergies_Materials: 'D824052B-4CC3-4853-A22F-AC710041A01C',
    allergies_Food: 'B96827FB-85A5-4199-A2B9-AC710041A01C',
    allergies_Medicine: 'DB3326B1-C6E5-4A76-AA69-AC710041A01C',
    allergies_Fabric: '1E6BE88F-BAF7-42BE-82C9-AC710041A01C',
    allergies_Rodents: '1E9998F2-BD17-4035-B6EF-AC710041A01C',
    allergies_Rabbits: 'E0FBFEB4-C5CA-43E1-826D-AC710041A01C',
    allergies_Horses: '597D1151-A77D-4621-81BA-AC710041A01C',
    allergies_Reptiles: 'CC07EC65-5386-4B58-ADBB-AC710041A01C',
    allergies_Cats: '2D3B374B-1D14-4D68-B886-AC710041A01C',
    allergies_Birds: 'D52E4456-5E41-44F8-9430-AC710041A01C',
    allergies_Dogs: '6486F768-58AC-4631-8298-AC710041A01C',

    // CBC question
    cbc_questtion_id: 'f457bca7-4b32-44af-84c2-e2a81b72c103',
    cbc_pass: '8cc05890-9a03-4dfa-90b8-a8c0236a2c84',
    cbc_exceptiongranted: 'c45dc2ec-261d-4188-a661-1409b340149b',
    cbc_hasAOR: 'b3b85cf7-acbc-4e1b-9b92-5b3fd50f5022',

    // Is the participant under the age of 18?
    checklist_underEighteen_yes: 'e856dff4-40d5-434b-ad03-a2f5e971fd26',
    checklist_underEighteen_no: '97c5be2d-b613-4dfb-8922-c5c7b88457b7',

    // DSS Check Status
    checklist_dss_inprogress: 'c05dd574-4ffe-4520-972c-8f96b39bb34f',
    checklist_dss_pass: 'd1d157a3-d8f1-47c1-844e-a6151839b174',
    checklist_dss_fail: '64d32267-b69d-45e7-b030-bbfa04bfbf0e',

    // Direct provider redirect url
    dirRedirectUrl: '/s4s/',

    // CRM page url
    crmUrl: 'ppl.crm.dynamics.com',

    // PPL CX AR MyAccount User
    ppl_cx_ar_security_group_id: '80364856-03b7-4d80-9908-b0fa01124df3',

    //Participant Registration
    participant_registration_address_question_id: 'd097221c-6fc9-4641-a528-266733f277ec',

    //Vendor Programs with Payment Details Tile
    vendor_payment_details_tile: [
        '93cefe92-5a46-469b-b29c-acfc003bc2d4' // KS STEPS
        , '8c965883-6485-42a3-b3c7-df7d20f35eb3' // CA SDP
        , 'fc05b030-ff24-4072-8f6c-ac60007a1a2e' // AL DMH
        , '1d47918d-9787-428b-9d80-ae9200bf0dd9' // OH RISE
        , '01c5ef03-01cf-4c50-aaa5-aeed00e31980' // MD DDA
        , 'a90f0c39-cea2-4879-a792-afa100ab78bf' // TN TCAD
        , '75f484fa-f1bd-4dcc-92cd-4140efdfaa23' // AR IC
        , '506499d1-23d2-4b03-981f-33272205a48b' // PA ODP
        , '4a696efa-3df2-4de3-9a95-08dee0b0ace2' // OH HOMECARE
    ],

    //Vendor Programs with My invoices Tile
    vendor_myinvoices_tile: [
        '93cefe92-5a46-469b-b29c-acfc003bc2d4' // KS STEPS
        , '8c965883-6485-42a3-b3c7-df7d20f35eb3' // CA SDP
        , '1d47918d-9787-428b-9d80-ae9200bf0dd9' // OH RISE
        , '01c5ef03-01cf-4c50-aaa5-aeed00e31980' // MD DDA
        , 'a90f0c39-cea2-4879-a792-afa100ab78bf' // TN TCAD
        , '75f484fa-f1bd-4dcc-92cd-4140efdfaa23' // AR IC
        , '506499d1-23d2-4b03-981f-33272205a48b' // PA ODP
        , '4a696efa-3df2-4de3-9a95-08dee0b0ace2' // OH HOMECARE
    ],

    //IP Providers Time Entry and Budgets Tile
    ip_provider_budgettool_tile: [
        '920b5f88-c351-4545-8372-d9498a55e2c1' // FL Simply
        , 'd5b58299-b6b5-413b-8997-af8901091e14' // FL PDO
        , '75f484fa-f1bd-4dcc-92cd-4140efdfaa23' // AR IC
        , 'a9e38c1b-734a-47ae-8341-ac0700b03759' // VA CCC
        , '4a696efa-3df2-4de3-9a95-08dee0b0ace2' // OH HOMECARE
    ]
}
